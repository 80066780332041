<template>
  <div class="applicationForm">
    <form>
      <h1>Candidature</h1>
      <span>Note :</span>
      <v-rating
        v-model="application.score"
        background-color="yellow lighten-1"
        color="yellow"
        length="10"
        large
        class="ratingStars"
      ></v-rating>
      <p>Montant du loyer : {{application.property ?  application.property.rent : '-'}}</p>
      <p>Url du logement : 
        <a target="blank" :href="propertyUrl">
          {{ propertyUrl }}
        </a>
      </p>

       <v-btn
          small
          class="success"
          @click="editRentalFile(application.rentalFileUid)"
        >
          Dossier : {{ application.rentalFile }}
       </v-btn>
        <v-select
          v-model="application.status"
          :model="application.status"
          :items="status"
          class="mt-5"
          @input="application.status = $event, $forceUpdate()"
          :reduce="status => status.value"
          label="Choissisez le statut de la candidature"
        />
        <span v-if="statusInfo" class="font-weight-light">
          {{statusInfo}}
        </span>
      <div>
        <v-btn
        class="info mt-5"
        @click="submit()"
      >
        Valider
      </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data:() => ({
    BaseUrl: process.env.VUE_APP_BASEURL,
    application: {},
    status: [
      {
        value :1, text: '1 - Crée'
      },
      {
        value :2, text: '2 - Refusé'
      },
      {
        value :3, text: '3 - Candidat accepté'
      },
      {
        value :4, text: '4 - Frais payés'
      },
      {
        value :5, text: '5 - Annulé'
      },
      {
        value :6, text: '6 - Locataire en place'
      },
    ],
  }),
  created(){
      axios.get(process.env.VUE_APP_APIURL + "/applications/" + this.$route.params.applicationUid,
        {
          headers: {
            "Access-Token": localStorage.getItem("token"),
          },
          params: {
            expand: 'property'
          }
        }
      ).then(response => {
        this.application = response.data
      })
  },
  computed:{
    propertyUrl(){
      return `${this.BaseUrl}/location/${this.application.property?.locality}-${this.application.property?.postalCode}/${this.application.property?.propertyTypeLabel}/${this.application.property?.rooms}-pieces/${this.application.property?.uid}/`
    },
    statusInfo(){
      if(this.application.status === 3){
        return 'Lorsque le propriétaire accepte un locataire et que le locataire n’a pas encore payé les frais'
      }
      if(this.application.status === 5){
        return 'Lorsque le locataire annule sa candidature, ou lorsque la candidature est annulée automatiquement par le système'
      }
      if(this.application.status === 6){
        return 'lorsque les frais ont été payés et que la date d’entrée dans le logement est passé'
      }
      return ''
    }
  },
  methods:{
    submit(){
      axios.put(process.env.VUE_APP_APIURL + '/applications/' + this.$route.params.applicationUid, {
        score:  this.application.score != null ? this.application.score : undefined,
        status:  this.application.status !=null ? this.application.status : undefined,
      }, {
        headers :{
          'Access-Token': localStorage.getItem('token')
        }
      })
      .then(response => {
        if ( response.status == 200 ) {
          document.location.reload();
        }
      })
      .catch(err => {
        alert('Une erreur est survenue !')
        console.log(err.response.data)
      })
    },
     editRentalFile(uid) {
      open(
        this.$router.resolve({ name: "rentalFile", params: { rentalFileUid: uid } }).href,
        "_blank"
      );
    },
  },
}
</script>

<style>

  .applicationForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @media screen and (max-width: 600px) {
    .applicationForm {
      width: 100%;
    }
    h1 {
      font-size: 1.6rem;
    }
    form {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 1rem;
    }

    div .rating {
      width: 100%;
    }

    div.v-rating.ratingStars {
      white-space: normal;
    }
  }
</style>